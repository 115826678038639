<template>
  <div>
  <!--<vue-recaptcha ref="recaptcha" sitekey="6Ld6mq8eAAAAAOdgdPU2rJjbY00BG7uhoXuVQpXA"></vue-recaptcha>-->
  <div :class="$isWeb ? 'login-container-h5': 'login-container'">
    <div class="content">
      <img src="../assets/img/login/login_left_img.png" class="login-img" />
      <div class="login" v-if="currentState === 'signIn'">
        <p class="title">Welcome to GAEA Pool</p>
        <p class="subtitle">You Know Who</p>
        <p class="subtitle">{{ error }}</p>
        <p class="subtitle">{{ info }}</p>

        <input
          placeholder="User Name"
          type="text"
          class="input"
          v-model="username"
        />
        <input
          placeholder="Password"
          type="password"
          class="input"
          v-model="password"
        />
        <span class="forget-pwd" @click="forgetPwd">Forgot Password？</span>
        <div class="btns">
          <span :class="['btn', 'btn-active']" @click="login">Log in</span>
          <span class="btn" @click="signUp">Sign up</span>
        </div>
      </div>
      <div class="login" v-if="currentState === 'signUp'">
        <p class="title">Welcome to GAEA Pool</p>
        <p class="subtitle">You Know Who</p>
        <p class="subtitle">{{ error }}</p>
        <p class="subtitle">{{ info }}</p>
        <input placeholder="Username" type="text" class="input" v-model="username" />
        <input
          placeholder="Password（8-32 characters）"
          type="password"
          class="input"
          v-model="password"
        />
        <input
          placeholder="Confirm Password"
          type="password"
          class="input"
          v-model="rePassword"
        />
        <div class="btns">
          <span :class="['btn']" @click="backToLogin">Log in</span>
          <span :class="['btn', 'btn-active']" @click="signUp">Sign up</span>
        </div>
      </div>
      <div class="login" v-if="currentState === 'comfirmEmail'">
        <p class="title">Your registered email address</p>
        <p class="subtitle">Welcome to GAEA Pool</p>
        <p class="subtitle">{{ error }}</p>
        <p class="subtitle">{{ info }}</p>
        <input placeholder="Email" type="text" class="input" v-model="email" />
        <div class="btns">
          <span class="btn btn-active" @click="confirmEmail">Submit</span>
          <span class="btn" @click="() => cancel('signIn')">Return</span>
        </div>
      </div>
      <div class="login" v-if="currentState === 'comfirmPwd'">
        <p class="title">Reset Password</p>
        <p class="subtitle">Welcome to GAEA Pool</p>
        <p class="subtitle">{{ error }}</p>
        <p class="subtitle">{{ info }}</p>
        <input
          placeholder="Password（8-32 characters）"
          type="password"
          class="input"
          v-model="password"
        />
        <input
          placeholder="Confirm Password"
          type="password"
          class="input"
          v-model="rePassword"
        />
        <div class="btns">
          <span class="btn btn-active" @click="changePwd">Submit</span>
          <span class="btn btn-active" @click="() => cancel('comfirmEmail')"
            >Return</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  components: { VueRecaptcha },
  data() {
    return {
      hasLogin: false,
      username: "",
      email: "",
      password: "",
      rePassword: "",
      currentState: "signIn",
      error: "",
      info: "",
      dialogVisible: false,
      emailCode: ''
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.currentState = to.params.type || "signIn";
    });
  },
  watch: {
    $route: function(val) {
      if (val.params.type && val.params.type !== this.currentState) {
        this.currentState = val.params.type;
        this.$router.push({
          name: "Login",
          params: {
            type: val.params.type,
          },
        });
      }
    },
  },
  methods: {
    changeRouter(name) {
      if (!name) return;
      this.currentState = name;
      this.$router.push({
        name: "Login",
        params: {
          type: name,
        },
      });
    },
    forgetPwd() {
      this.changeRouter("comfirmEmail");
    },
    backToLogin() {
      this.changeRouter("signIn");
    },

    login() {
      if (!this.validateLogin()) return;
      //this.$refs.recaptcha.execute();
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$store.commit("setUsername", this.username);
          this.$router.push("/my");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            this.error = "UserName or Password Error";
          } else {
            this.error = "Login Error";
          }
        });

      //this.changeRouter('signUp')
    },

    signUpValidate(){
      console.log(this.username)
      let regex=/[A-Z]/
      if(this.username.search(regex) !== -1){
        // 用户名不要用大写字母
        this.error = "Do not use capital letters for user names"
        return false
      }

      if(this.username.includes('_')){
        // 用户名中间不能包含 
        this.error = "User name cannot contain '_'" 
        return false
      }
      if(this.username.includes('.')){
        // 用户名中间不能包含
        this.error = "User name cannot contain  '.'"
        return false
      }
      if(this.username.includes('@')){
        // 请不要用邮箱注册 
        this.error = "Please don't register by email"
        return false
      }
      if( (!this.username) || (!this.password) || (!this.rePassword)){
        // 缺少必填项
        this.error = 'Missing required item'
        return false
      }
      if(this.password != this.rePassword){
        // 两次输入的密码不一致
        this.error = 'The two passwords are inconsistent'
        return false
      }
      if(this.password.length < 8){
        // 密码长度需要等于或大于8
        this.error = 'Password length should be equal to or greater than 8'
        return false
      }
      return true
    },

    async signUp() {
      if(this.currentState != 'signUp'){
        this.changeRouter("signUp");
      }else{
        if(!this.signUpValidate()){
          console.log(this.error)         
          return
        }
        
        try{
          await this.$http.post('api/auth/register/', {
            username: this.username,
            password: this.password,
          })
          // 注册成功，请登陆吧
          this.info = 'Successful registration, please login'
          //this.$messageBus.$emit('registered')
        }catch(error){
          const msg = error.response.data['message']
          if(msg === 'username exists'){
            // 用户名已经存在, 换一个吧.
            this.error = 'The user name already exists. Change it'
          }else{
            this.error = msg
          }
        }
      }
    },

    cancel(state) {
      if (!state) return;
      this.changeRouter(state);
    },
    // 邮箱确认
    confirmEmail() {
      this.dialogVisible = true;
    },
    verifyEmail() {
      this.dialogVisible = false;
      this.changeRouter("comfirmPwd");
    },
    // 密码确认
    changePwd() {
      this.changeRouter("signIn");
    },
    validateLogin() {
      if (!this.username || !this.password) {
        this.error = "Missing required";
        return false;
      }
      return true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less">
.login-container{
  width: 100%;
  height: 100%;
  background-color: #101010;
  padding-top: 193px; /*no*/
  box-sizing: border-box;
  .content{
    display:flex;
    justify-content: center;
    align-items: center;
    .login-img{
      width: 292px;/*no*/
      height: 292px;/*no*/
      margin-right: 65px;/*no*/
    }
  }
  .login{
    width: 245px;/*no*/
    .title{
      font-size: 17px;/*no*/
      line-height: 20px;/*no*/
      color: #fff;
      text-align: center;
    }
    .subtitle{
      font-size: 14px;/*no*/
      line-height: 16px;/*no*/
      color: #FFFFFF;
      text-align: center;
      margin-top: 2px;/*no*/
      margin-bottom: 6px;/*no*/
    }
    .input{
      margin-top: 20px;/*no*/
      width: 100%;
      background-color: #101010;
      box-sizing: border-box;
    }
    .forget-pwd{
      color: #fff;
      font-size: 10px;/*no*/
      float: right;
      margin-top: 4px;/*no*/
      cursor: pointer;
    }
    .btns{
      margin-top: 30px;/*no*/
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      .btn {
        width: 110px;/*no*/
        height: 33px;/*no*/
        color: #fff;
        display: flex;
        justify-content:center;
        align-items: center;
        border: 1px solid #893EFF;
        cursor: pointer;
      }
      .btn-active{
        background-color: #893EFF;
      }
    }
  }
}
.login-container-h5{
  width: 100%;
  height: 100%;
  background-color: #101010;
  padding-top: 135px;
  box-sizing: border-box;
  .content{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .login-img{
      width: 298px;
      height: 298px;
    }
  }
  .login{
    width: 500px;
    margin-top: 40px;
    .title{
      font-size: 37px;
      line-height: 44px;
      color: #fff;
      text-align: center;
      font-weight: 600;
    }
    .subtitle{
      font-size: 26px;
      line-height: 31px;
      color: #FFFFFF;
      text-align: center;
      margin-top: 7px;
      margin-bottom: 17px;
    }
    .input{
      margin-top: 33px;
      width: 100%;
      height: 55px;
      background-color: #101010;
      box-sizing: border-box;
    }
    .forget-pwd{
      color: #fff;
      font-size: 10px;
      float: right;
      margin: 14px 0 40px;
      cursor: pointer;
    }
    .btns{
      margin-top: 80px;
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      width: 100%;
      .btn {
        width: 229px;
        height: 55px;
        color: #fff;
        display: flex;
        justify-content:center;
        align-items: center;
        border: 1px solid #893EFF;
        cursor: pointer;
      }
      .btn-active{
        background-color: #893EFF;
      }
    }
  }
}
</styles>
